import React from "react";

const shapes = {
    square: "rounded-[0px]",
    round: "rounded",
} as const;
const variants = {
  fill: {
    deep_purple_A200: "bg-deep_purple-A200 text-black-900",
  },
} as const;
const sizes = {
    md: "h-[45px] px-2 text-[25px]",
    xs: "h-[34px] px-3 text-lg",
    sm: "h-[37px] px-2",
    lg: "h-[64px] px-[35px] text-[26px]",
} as const;

type ButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onClick"
> &
  Partial<{
    className: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
  }>;
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "round",
  variant = "fill",
  size = "xs",
  color = "deep_purple_A200",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex items-center justify-center text-center cursor-pointer text-black-900 rounded-tl-[31px] rounded-tr-[31px] rounded-bl-[30px] rounded-br-[30px] text-3xl bg-deep_purple-A200 min-w-[220px] ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color as keyof (typeof variants)[typeof variant]]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
