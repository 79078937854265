import React from 'react'
import { useRoutes } from 'react-router-dom'
import Home from 'pages/Home'
import NotFound from 'pages/NotFound'
import Webp101One from 'pages/Webp101One'
import AirdropPage from "pages/Airdrop";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: '/', element: <Webp101One /> },
    { path: '*', element: <NotFound /> },
    { path: '/airdrop', element: <AirdropPage /> },
    {
      path: 'webp101one',
      element: <Webp101One />,
    },
  ])

  return element
}

export default ProjectRoutes
