import React from 'react'
import Routes from './Routes'
import { BrowserRouter as Router } from 'react-router-dom'
import './styles/font.css'
import './styles/tailwind.css'
import './styles/font.css'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCfVZS1Nl_KvT-QlZpRFvelpgJM3yl2x8U",
  authDomain: "rtdao-98b2a.firebaseapp.com",
  projectId: "rtdao-98b2a",
  storageBucket: "rtdao-98b2a.appspot.com",
  messagingSenderId: "212560279757",
  appId: "1:212560279757:web:da4a6056da13b57c45d184",
  measurementId: "G-04TDSZH4CC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  return (
    <>
      <Router>
        <Routes />
      </Router>
    </>
  )
}

export default App
